import React from 'react';
import { Wrapper } from './atreo.styled';

export const Atreo: React.FC = () => {
    const SvgLogo = () => (
        <svg width="68" height="26" viewBox="0 0 68 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 25.9998H14.0676V21.7292H0V25.9998Z" fill="#38C659" />
            <path
                d="M0 10.3379C0 8.93281 0.195732 7.69925 0.707432 6.63721C1.23513 5.5752 1.94671 4.75826 2.84219 4.18641C3.73766 3.61455 4.54868 3.32863 5.65203 3.32863C6.59546 3.32863 7.41898 3.52469 8.12262 3.91682C8.8422 4.30895 9.41332 4.82668 9.7971 5.46389V3.32861L14.0676 3.32863V17.3962H9.7971V15.261C9.39731 15.8982 8.81821 16.4159 8.09862 16.808C7.39499 17.2002 6.57147 17.3962 5.62805 17.3962C4.54068 17.3962 3.73766 17.1103 2.84219 16.5385C1.94671 15.9503 1.23513 15.1252 0.707432 14.0632C0.195732 12.9848 0 11.7431 0 10.3379ZM9.77763 10.3624C9.77763 9.31678 9.48981 8.49169 8.91411 7.88715C8.35448 7.28262 7.66686 6.9803 6.85132 6.9803C6.03581 6.9803 5.34021 7.28262 4.76455 7.88715C4.20488 8.4753 3.92504 9.29229 3.92504 10.3379C3.92504 11.3836 4.20488 12.2169 4.76455 12.8378C5.34021 13.4423 6.03581 13.7446 6.85132 13.7446C7.66686 13.7446 8.35448 13.4423 8.91411 12.8378C9.48981 12.2332 9.77763 11.4081 9.77763 10.3624Z"
                fill="black"
            />
            <path
                d="M25.4311 13.8243V17.3961H23.258C21.7094 17.3961 20.5021 17.0829 19.6362 16.331C18.7703 15.5623 18.3373 14.3173 18.3373 12.5961L18.3345 7.62635H16.1992V3.34033H18.3373V0H22.6086L22.605 3.34033H25.9963L25.9713 7.62635H22.6086V12.6462C22.6086 13.0639 22.7085 13.3648 22.9083 13.5486C23.1081 13.7324 23.4412 13.8243 23.9075 13.8243H25.4311Z"
                fill="black"
            />
            <path
                d="M32.3956 5.84044C32.8962 5.07452 33.5489 4.48011 34.3 4.0472C35.051 3.59764 35.8855 3.32861 36.8034 3.32861V7.59915C36.8034 7.59915 35.987 7.56774 35.4217 7.66195C34.8565 7.75615 33.7576 8.12656 33.2235 8.59273C32.6894 9.04227 32.4224 9.84149 32.4224 10.9904L32.3955 17.3948L28.1416 17.3962L28.1367 3.32861H32.3956V5.84044Z"
                fill="black"
            />
            <path
                d="M51.9348 10.1419C51.9348 10.534 51.9097 10.9425 51.8595 11.3673H42.1377C42.2047 12.2168 42.4811 12.8704 42.9667 13.3279C43.4691 13.7691 44.0804 13.9896 44.8005 13.9896C45.8724 13.9896 46.6176 13.5485 47.0363 12.6662H51.6083C51.3738 13.5648 50.9468 14.3736 50.3271 15.0925C49.7242 15.8114 48.9622 16.3751 48.0411 16.7836C47.12 17.192 46.09 17.3962 44.9512 17.3962C43.578 17.3962 42.3555 17.1103 41.2836 16.5384C40.2118 15.9666 39.3744 15.1497 38.7715 14.0877C38.1686 13.0257 37.8672 11.7839 37.8672 10.3624C37.8672 8.94097 38.1603 7.69919 38.7464 6.63721C39.3493 5.57519 40.1867 4.75825 41.2585 4.18639C42.3303 3.61454 43.5613 3.32861 44.9512 3.32861C46.3078 3.32861 47.5136 3.60637 48.5686 4.16189C49.6237 4.7174 50.4443 5.50983 51.0305 6.53917C51.6334 7.5685 51.9348 8.7694 51.9348 10.1419ZM47.5387 9.03901C47.5387 8.32011 47.2875 7.74824 46.7851 7.32344C46.2826 6.89865 45.6546 6.68619 44.901 6.68619C44.1809 6.68619 43.5696 6.89043 43.0672 7.29895C42.5815 7.70742 42.2801 8.28739 42.1628 9.03901H47.5387Z"
                fill="black"
            />
            <path
                d="M60.4931 17.3962C59.1672 17.3962 57.9706 17.1103 56.9034 16.5384C55.8524 15.9666 55.0197 15.1497 54.4053 14.0877C53.8069 13.0257 53.5078 11.7839 53.5078 10.3624C53.5078 8.9573 53.815 7.72374 54.4295 6.6617C55.0439 5.58335 55.8847 4.75825 56.9519 4.18639C58.0191 3.61454 59.2157 3.32861 60.5416 3.32861C61.8676 3.32861 63.0644 3.61454 64.1314 4.18639C65.1984 4.75825 66.0393 5.58335 66.6535 6.6617C67.2683 7.72374 67.5754 8.9573 67.5754 10.3624C67.5754 11.7676 67.2602 13.0093 66.6296 14.0877C66.0148 15.1497 65.1664 15.9666 64.083 16.5384C63.0154 17.1103 61.819 17.3962 60.4931 17.3962ZM60.4931 13.7691C61.2855 13.7691 61.9565 13.475 62.5063 12.8868C63.0719 12.2986 63.3552 11.4571 63.3552 10.3624C63.3552 9.26773 63.0801 8.42631 62.5305 7.83811C61.9969 7.2499 61.3339 6.9558 60.5416 6.9558C59.7331 6.9558 59.0621 7.2499 58.5285 7.83811C57.9949 8.40998 57.7281 9.2514 57.7281 10.3624C57.7281 11.4571 57.9868 12.2986 58.5043 12.8868C59.0378 13.475 59.7008 13.7691 60.4931 13.7691Z"
                fill="black"
            />
        </svg>
    );

    return (
        <Wrapper href={'https://atreo.cz'} target="_blank">
            Vytvořilo
            <SvgLogo />
        </Wrapper>
    );
};
