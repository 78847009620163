import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { Copy, Copyright, Main, SocialLinks, MainTop, Cookies, Sep } from '../footer.styled';
import { routes } from '../../../../config/routes';
import useCookies from '../../../../hooks/use-cookies';
import { Logo } from '../../../../components/app/logo/logo';
import { Social } from '../../../../components/web/social/social';
import { IconCookies } from '../../../../components/app/icons/cookies';
import { Atreo } from '../../../../components/app/atreo/atreo';

export const FooterContent: React.FC = () => {
    const { t } = useTranslation();
    const { handleShowPreferences } = useCookies(false);
    return (
        <>
            {/* <Navigation> */}
            {/* <NavigationList title="Products">
                        <a href="">Performance tracking</a>
                        <a href="">Dividend tracking</a>
                        <a href="">Tax reporting</a>
                        <a href="">Pricing</a>
                    </NavigationList> */}
            {/* <NavigationList title="Resources">
                        <a href="">Blog</a>
                        <a href="">Contact</a>
                    </NavigationList> */}
            {/* <NavigationList title={t('pages.layout.footer.legal')}>
                        <Link href={routes.web.termsOfService}>
                            <a>{t('pages.layout.footer.termsOfService')}</a>
                        </Link>
                        <a href="">{t('pages.layout.footer.cookies')}</a>
                        <a href="">{t('pages.layout.footer.terms')}</a>
                    </NavigationList> */}
            <Copy>
                <Main>
                    <MainTop>
                        <Logo href={routes.web.index} />
                        <Sep />
                        <SocialLinks>
                            <Social type="Facebook" link="https://www.facebook.com/monery.io" />
                            <Social type="Instagram" link="https://www.instagram.com/monery.io" />
                            <Social type="Discord" link="https://discord.gg/n4MYgUh9KF" />
                        </SocialLinks>
                        <Sep />
                        <Cookies onClick={handleShowPreferences}>
                            <IconCookies />
                        </Cookies>
                    </MainTop>
                    <Copyright>
                        &copy; Monery Finance s.r.o &mdash;{' '}
                        <Link href={routes.web.termsOfService}>
                            <a>{t('pages.layout.footer.termsOfService')}</a>
                        </Link>
                    </Copyright>
                </Main>

                <Atreo />
            </Copy>
        </>
    );
};
