import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        a {
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3.6rem;
            width: 3.6rem;
            border-radius: 100%;
            border: 1px solid ${theme.palette.color.gray.opacity.high};
            transition: all ${theme.base.transition};
            color: ${theme.palette.color.secondary.main};

            i {
                width: 1.6rem;
                height: 1.6rem;
            }

            &:hover {
                background: ${theme.button.variant.primary.filled.hover.background};

                i {
                    color: ${theme.palette.color.white.main};
                }
            }
        }
    `,
);
