import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SignUpForm } from '../../forms/sign-up-form';
import { ThirdPartyLogin } from './third-party-login/third-party-login';
import { SignUpUserMobxDto } from '../../../../models/mobx/dtos/auth/sign-up-user-mobx-dto';
import { useRootStore } from '../../../../providers/root-store-provider';
import { DialogAccountLinks } from './links/links';
import { BaseDialog } from '../base-dialog';
import { IconUser } from '../../../app/icons/user';
import { IconButton } from '../../../app/icon/button/icon-button';

export interface SignUpDialogInterface {
    action?: string;
    status?: string;
}

export const SignUpDialog: React.FC<SignUpDialogInterface> = observer((props: SignUpDialogInterface) => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const { alertStore } = rootStore;
    const { authDialogStore } = rootStore.authStore;
    const { status } = props;

    useEffect(() => {
        if (status) {
            alertStore.setErrorMessage(String(status));
            authDialogStore.setLoginActive(true);
        }
    }, []);

    const onSignup = async (values: SignUpUserMobxDto) => {
        await rootStore.authStore.signUp(values);
    };

    return (
        <>
            <BaseDialog
                opened={authDialogStore.activeModal === 'signUp'}
                close={() => authDialogStore.closeDialog()}
                icon={<IconButton icon={<IconUser />} variant={'primary'} />}
                title={
                    authDialogStore.authStore.registrationDone
                        ? t('dialogs.signUp.titleDone')
                        : t('dialogs.signUp.title')
                }
                content={
                    <>
                        {authDialogStore.authStore.registrationDone && <>{t('dialogs.signUp.warningMessage')}</>}

                        {!authDialogStore.authStore.registrationDone && (
                            <>
                                <SignUpForm onSubmit={onSignup} loading={authDialogStore.loading} />
                                <DialogAccountLinks type={authDialogStore.activeModal} />
                                <ThirdPartyLogin />
                            </>
                        )}
                    </>
                }
            />
        </>
    );
});
