import styled from 'styled-components';

export const Wrapper = styled.a`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.6rem;
    align-items: flex-end;
    cursor: pointer;
    color: inherit;
    text-decoration: none;

    @media (max-width: 768px) {
        align-items: center;
    }
`;
