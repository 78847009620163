import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { RedirectActionEnum } from '@app/common';
import { BaseDialog } from '../base-dialog';
import { LoginForm } from '../../forms/login-form';
import { ThirdPartyLogin } from './third-party-login/third-party-login';
import { LoginUserMobxDto } from '../../../../models/mobx/dtos/auth/login-user-mobx-dto';
import { useRootStore } from '../../../../providers/root-store-provider';
import { routes } from '../../../../config/routes';
import { DialogAccountLinks } from './links/links';
import { IconUser } from '../../../app/icons/user';
import { Alert } from '../../../app/alert/alert';
import { IconButton } from '../../../app/icon/button/icon-button';

export interface LoginDialogInterface {
    status?: string;
    action?: string;
    token?: string;
    setEmail: (email: string) => void;
}

export const LoginDialog: React.FC<LoginDialogInterface> = observer((props: LoginDialogInterface) => {
    const { t } = useTranslation();
    const router = useRouter();
    const rootStore = useRootStore();
    const { authDialogStore } = rootStore.authStore;

    useEffect(() => {
        if (props.action === RedirectActionEnum.ConfirmEmail && props.token) {
            rootStore.authStore.confirmEmail(props.token);
        }
    }, [props.action, props.token]);

    const onLogin = async (values: LoginUserMobxDto) => {
        try {
            await rootStore.authStore.login(values);
            authDialogStore.closeDialog();

            if (router.query.redirect) {
                window.location.replace(router.query.redirect as string);
            } else {
                window.location.href = routes.app.index;
            }
        } catch (e) {
            rootStore.authStore.authDialogStore.setLoading(false);
            rootStore.alertStore.setErrorMessage(e.message);
            props.setEmail(values.email);
            // eslint-disable-next-line no-console
            console.error(e);
        }
    };

    return (
        <BaseDialog
            opened={authDialogStore.activeModal === 'login'}
            close={() => authDialogStore.closeDialog()}
            icon={<IconButton icon={<IconUser />} variant={'primary'} />}
            title={t('dialogs.login.title')}
            content={
                <>
                    {rootStore.authStore.authDialogStore.confirmDone && (
                        <Alert type={'success'} icon>
                            {t('dialogs.confirmEmail.confirmMessage')}
                        </Alert>
                    )}

                    {rootStore.authStore.authDialogStore.confirmLoading && (
                        <Alert type={'warning'} icon loading>
                            Ověřujeme váš účet
                        </Alert>
                    )}

                    {!rootStore.authStore.authDialogStore.confirmLoading && (
                        <>
                            <LoginForm onSubmit={onLogin} loading={authDialogStore.loading} />
                            <DialogAccountLinks type={authDialogStore.activeModal} />
                            <ThirdPartyLogin />
                        </>
                    )}
                </>
            }
        />
    );
});
